<template>
	<div class="app-container">
		<el-header class="titles">各省运费管理</el-header>
		<el-card id="parent" class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空</el-button>
				<el-button style="float: right" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="searchForm" ref="searchForm" size="small" label-width="100px">
					<el-form-item label="省份：" prop="address">
						<el-select v-model="searchForm.address" clearable placeholder="请选择省份" autocomplete="off" style="max-width: 200px;">
							<el-option v-for="item in addrArr" :key="item.code" :label="item.name" :value="item.code"></el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item label="省代号：" prop="code">
						<el-input v-model="searchForm.code" placeholder="请输入省代号"></el-input>
					</el-form-item> -->
					<el-form-item label="是否启用：" prop="status">
						<el-select v-model="searchForm.status" clearable placeholder="请选择" style="max-width: 200px;">
						    <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="append()">新增</el-button>
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
				    <el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button slot="reference" type="primary" size="medium" icon="el-icon-s-grid" circle></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="page = 1; getList()"></el-button>
		</el-row>
		<el-card>
			<el-table v-loading="listLoading" ref="multipleTable" :data="tableData" :stripe="true" border style="width: 100%">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :label="item.label" :sortable="item.sortable" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'status'">{{scope.row.status == 0 ? '禁用' : '启用'}}</span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" min-width="150">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" icon="el-icon-edit" @click="editTable(scope.$index, scope.row)">修改</el-button>
						<!-- <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteTable(scope.$index, scope.row)">删除</el-button> -->
						<el-switch v-model="scope.row.status" @change="switChange($event, scope.row)" active-color="#409eff" inactive-color="#ff4949" active-text="启用" inactive-text="禁用" :active-value="1" :inactive-value="0"></el-switch>
					</template>
				 </el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="40%" v-loading="dialogLoading">
		  <el-form :model="form" :rules="rules" ref="ruleForm">
		    <el-form-item label="省份：" prop="name" :label-width="formLabelWidth">
				<el-select v-model="form.name" clearable placeholder="请选择省份" @change="addrNameChange" autocomplete="off" style="max-width: 200px;">
					<el-option v-for="item in addrArr" :key="item.code" :label="item.name" :value="item.code"></el-option>
				</el-select>
		    </el-form-item>
		    <el-form-item label="省份代码：" prop="code" :label-width="formLabelWidth">
				<el-input v-model="form.code" autocomplete="off" disabled></el-input>
		    </el-form-item>
		    <el-form-item label="运费：" prop="num" :label-width="formLabelWidth">
				<el-input v-model="form.num" autocomplete="off"></el-input>
		    </el-form-item>
		    <el-form-item label="备注：" prop="remark" :label-width="formLabelWidth">
				<el-input type="textarea" v-model="form.remark" placeholder="请输入备注" autocomplete="off"></el-input>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="dialogFormVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogConfirm('ruleForm')">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogLoading: false,
				dialogTitle: '修改数据',
				page: 1,
				statusArr: [{
					value: 0,
					label: "禁用"
				}, {
					value: 1,
					label: "启用"
				}],//是否启用
				addrArr: [],//地址
				searchForm: {
					address: '',
					code: '',
					status: ''
				},//条件查询form
				tableData: [],//表格
				total: 0,//分页总条数
				dialogFormVisible: false,//修改、新增弹窗
				formLabelWidth: '100px',//修改、新增弹窗-label宽度
				form: {
					name: '',
					code: '',
					num: '',
					remark: ''
				},//修改、新增弹窗-表单内容
				rules: {
					name: [{ required: true, message: '请输入省份', trigger: 'blur' }],
					code: [{ required: true, message: '请输入省份代码', trigger: 'blur' }],
					num: [{ required: true, message: '请输入运费', trigger: 'blur' }]
				},
				visible: false,
				checkArr: [//{label: "id", val: "id", dis: true},
					{label: "省份名称", val: "provinceName", dis: true},
					{label: "省代号", val: "provinceCode", dis: true},
					{label: "运费", val: "freight", dis: true},
					{label: "是否启用", val: "status", dis: true},
					{label: "备注", val: "remark", dis: true}
				],
				checkList: ["provinceName", "provinceCode", "freight", "status", "remark"]
				// checkList: [{label: "类型1", val: "name"}, {label: "默认题目数量", val: "defaultQuestionNum"}, {label: "默认分值", val: "defaultScore"}, {label: "默认考试顺序", val: "defaultSort"}]
			}
		},
		components: {
			
		},
		created(){
			this.page = 1;
			this.getList();
		},
		methods:{
			getList() {
				this.listLoading = true;
				let data = {
					provinceName: this.searchForm.address,
					enabled: this.searchForm.status,
					page: this.page,
					pageSize: 10
				}
				this.$comjs.ajax.getAjax('/fa/exam/freight/query', data, this, res => {
					// console.log(res)
					this.tableData = res.list;
					this.total = res.total;
					this.listLoading = false;
				});
				this.$comjs.ajax.getAjax('/fa/exam/delivery-address/area-query', {}, this, res => {
					// console.log(res)
					this.addrArr = res.list;
				});
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			// 修改-省份选择
			addrNameChange(val) {
				// console.log(val, this.form.name)
				let obj = this.addrArr.find(item => {return item.code == val});
				this.form.name = obj.name;
				this.form.code = val;
			},
			// 新增
			append() {
				if (this.$refs.ruleForm) {
					this.$refs.ruleForm.clearValidate();
				}
				this.dialogTitle = "新增数据";
				this.dialogFormVisible = true;
				this.form = {
					id: '',
					name: '',
					code: '',
					num: '',
					remark: ''
				}
			},
			// 修改
			editTable(index, row) {
				if (this.$refs.ruleForm) {
					this.$refs.ruleForm.clearValidate();
				}
				this.dialogFormVisible = true;
				this.dialogTitle = "修改数据";
				let data = {
					id: row.id
				}
				this.$comjs.ajax.getAjax('/fa/exam/freight/query', data, this, res => {
					// console.log(res)
					this.form = {
						id: res.data.id,
						name: res.data.provinceName,
						code: res.data.provinceCode,
						num: res.data.freight,
						remark: res.data.remark,
					}
				})
			},
			// 删除
			deleteTable(index, row) {
				let data = {
					id: row.id,
				}
				this.$comjs.ajax.postAjax('/fa/exam/advert/del', data, this, res => {
					// console.log(res)
					// console.log(row.id)
					this.tableData.splice(index, 1);
				});
			},
			// 表格-是否启用
			switChange(val, row) {
				let data = {
					id: row.id
				}
				this.$comjs.ajax.postJsonAjax('/fa/exam/freight/enabled', data, this, res => {
					this.$message({
						message: res.message,
						type: 'success'
					});
					this.getList();
				});
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.page = 1;
				this.getList();
			},
			// 搜索
			searchTable() {
				// let val = this.searchForm.name;
				this.page = 1;
				this.getList();
			},
			// 修改/新增-确定
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {
							id: this.form.id,
							provinceName: this.form.name,
							provinceCode: this.form.code,
							freight: this.form.num,
							remark: this.form.remark
						}
						let url = this.dialogTitle == "修改数据" ? '/fa/exam/freight/edit' : '/fa/exam/freight/add';
						// console.log(data)
						this.$confirm('是否提交数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$comjs.ajax.postJsonAjax(url, data, this, res => {
								this.dialogFormVisible = false;
								this.$refs[formName].resetFields();
								this.$message({
									message: '保存成功！',
									type: 'success',
									duration: 1000
								});
								// this.page = 1;
								this.getList();
							});
						}).catch(() => {
							
						});
					}
				});
			},
			// 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			}
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.elCard {
		margin-top: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
	#parent {
		position: relative;
	}
</style>


